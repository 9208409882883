.SideBar {
  .logo {
    font-size: 21px;
    font-weight: 300;
    line-height: 70px;
    letter-spacing: 3px;
    text-transform: uppercase;
    color: rgb(249, 249, 249);
    display: block;
    text-align: center;
    text-decoration: none;
  }
  .ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal)
    .ant-menu-item-selected {
    background-color: rgba(255, 255, 255, 0.08);
    border-radius: 8px;
  }
  .ant-menu-item {
    div {
    }
    color: #111827;
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
  }
  .ant-menu-item-selected {
    div {
      color: #52ba81;
      font-family: "Inter";
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 24px;
    }
  }
  .trigger {
    font-size: 18px;
    line-height: 64px;
    cursor: pointer;
    transition: color 0.3s;
    color: #fff;
  }

  .d_flex{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 24px;
  }
}
