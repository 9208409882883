@import "../../styles/mixins";
@import "../../styles/variables";

.ordersWrapper {
  padding: 30px;
  @include mobile {
    padding: 30px 8px;
  }
  .heading-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .add-btn {
    height: auto;
    background: $ExploreBtnBg;
    border: none !important;
    border-radius: 10px;
    width: max-content;

    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    text-transform: capitalize;
    color: $textColor;
  }
  .title {
    color: #111827;
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 34px;
    line-height: 24px;
    padding-bottom: 10px;
  }
  .ant-card {
    border-radius: 10px !important;
    box-shadow: 0px 4px 16px -7px rgba(83, 82, 237, 0.57);
  }
}
.actions-wrapper {
  display: flex;
  gap: 12px;
}
.orderDetails {
  cursor: pointer;
  color: #1890ff;
}
.delete-btn {
  cursor: pointer;
  color: rgb(227, 76, 76);
}
