@import "../../styles/mixins";
@import "../../styles/variables";
.mainWrapper {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  .loginFormWrapper {
    display: flex;
    justify-content: center;
    width: 80%;
    @include mobile {
      padding: 0% 5%;
      width: 100%;
    }

    .loginForm {
      width: 60%;
      padding: 20px 35px;
      background: $textColor;
      box-shadow: 0px 4px 16px -7px rgba(83, 82, 237, 0.57);
      border-radius: 10px;
      @include mobile {
        width: 100%;
      }
      .orText {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        border-bottom: 1px dashed rgba(48, 53, 73, 0.25);
        span {
          // margin-bottom: -12px;
          background: $textColor;
        }
      }
      .form {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        .toLoginText {
          font-style: normal;
          font-weight: 400;
          font-size: 18px;
          line-height: 35px;
          color: $ExploreBtnBg;
        }
        .inputField {
          width: 100%;
          // margin-top: 23px;
        }
        .MT {
          // margin-top: 59px;
        }
        .ant-form {
          width: 100%;
          // margin-top: 59px;
        }
        .ant-btn {
          height: 50px;
          background: $ExploreBtnBg;
          border: none !important;
          border-radius: 10px;
          width: 100%;

          font-style: normal;
          font-weight: 700;
          font-size: 20px;
          line-height: 24px;
          text-transform: capitalize;
          color: $textColor;
        }
        .unsetLeftBorder {
          .ant-input {
            border-radius: 0px 10px 10px 0px !important;
          }
          .ant-input-group-addon {
            border-radius: 10px 0px 0px 10px !important;
          }
        }
        .ant-input-group-addon {
          background: $InputBg !important;
          line-height: 2.9715;
          background: $InputBg !important;
          border-radius: 10px !important;
          border: none !important;
        }
        .ant-input-affix-wrapper {
          background: $InputBg !important;
          line-height: 2.9715;
          background: $InputBg !important;
          border-radius: 10px !important;
          border: none !important;
        }
        .ant-input {
          background: $InputBg !important;
          line-height: 2.9715;
          background: $InputBg !important;
          border-radius: 10px !important;
          border: none !important;
        }
        .formHeadingWrapper {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          margin-bottom: 5px;
          .loginAcc {
            font-style: normal;
            font-weight: 700;
            font-size: 24px;
            line-height: 28px;
            color: $ExploreBtnBg;
          }
        }
      }
    }
  }
}
