.site-layout-background {
    display: flex;
    padding: 0px 10px;
    justify-content: space-between;
    align-items: center;
    .ShopLogo {
        color: #0F1111;
        padding: 5px;
        margin-left: 10px;
        cursor: pointer;
        transition: all 0.3s ease-in-out;
        &:hover {
            color: #1890FF;
        }
        
        svg {
            width: 30px !important;
            height: 30px !important;
        }

    }
    .USERLOGO {
        display: flex;
        align-items: center ;
        justify-content: space-between;
        align-items: center;
        .UserName {
            font-size: 16px;
            font-weight: 700;
            margin-bottom: 0;
            margin-right: 10px;
        }
        .profileImage{
            border-radius: 50%;
            object-fit: contain;
            width: 40px;
            margin-right: 12px;
        }
    }
    .anticon {
        svg {
            width: 20px;
            height: 20px;
        }
    }
}