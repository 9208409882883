@import './styles/mixins';

.ant-layout {
  height: max-content;
}
.ant-layout-sider {
  background: #111827;
}
.main_page_width {
  max-width: 1560px;
  margin-left: auto;
  margin-right: auto;
}
.ant-menu.ant-menu-dark {
  background: #111827;
}
.ant-drawer-body{
  overflow-x: hidden;
}
.ant-drawer-header {
  background: #111827 !important;
  .ant-drawer-title {
    color: #52ba81;
  }
  .ant-drawer-close {
    color: #52ba81;
  }
}
body {
  background-color: #f0f2f5;
}
.ant-modal-body {
  max-height: 500px;
  overflow-y: auto;
  overflow-x: hidden;
}
.ant-layout.ant-layout-has-sider>.ant-layout{
  @include mobile{
    width: 100%;
  }
}
.ant-card{
  width: 100%;
}
.ant-table-wrapper{
  // @include mobile{
    overflow: auto;
  // }
}