@import "../../styles/variables";

.add-blog {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  padding: 60px 0px;
  .heading {
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
    color: $ExploreBtnBg;
  }
  .ant-form {
    width: 90%;
  }
  .ant-input {
    line-height: 2.9715;
    background: $InputBg !important;
    border-radius: 10px !important;
  }
  .ant-btn {
    height: 50px;
    background: $ExploreBtnBg;
    border: none !important;
    border-radius: 10px;
    width: 200px;

    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    text-transform: capitalize;
    color: $textColor;
  }
}
